import { render, staticRenderFns } from "./MainCollapse.vue?vue&type=template&id=77c31fc3&scoped=true"
import script from "./MainCollapse.vue?vue&type=script&lang=js"
export * from "./MainCollapse.vue?vue&type=script&lang=js"
import style0 from "./MainCollapse.vue?vue&type=style&index=0&id=77c31fc3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c31fc3",
  null
  
)

export default component.exports