<script>
import SubscriptionHistoryItem from "@/components/subscription/SubscriptionHistoryItem.vue";
import MainButton from "@/components/helpers/MainButton";
import MainCollapse from "@/components/helpers/MainCollapse";

export default {
  components: { MainCollapse, MainButton, SubscriptionHistoryItem },

  props: {
    subscriptionHistory: {
      type: Array,
      required: true,
    },

    subscriptionHistoryCurrentPage: {
      type: Number,
      required: true,
    },

    subscriptionHistoryLastPage: {
      type: Number,
      required: true,
    },
  },
};
</script>

<template>
  <MainCollapse
    class="subscription-history-list"
    title="Списания абонентской платы"
  >
    <div class="subscription-history-list__wrapper">
      <SubscriptionHistoryItem
        v-for="subscriptionHistoryItem in subscriptionHistory"
        :key="subscriptionHistoryItem.id"
        :subscription-history-item="subscriptionHistoryItem"
      />

      <MainButton
        v-if="subscriptionHistoryCurrentPage < subscriptionHistoryLastPage"
        class="subscription-history-list__button"
        color="outlined"
        title="Показать еще 10 операций"
        @click.native="$emit('nextPage')"
      />
    </div>
  </MainCollapse>
</template>

<style lang="scss" scoped>
.subscription-history-list {
  &__button {
    max-width: 272px;
  }
}
</style>
