export const SUBSCRIPTION_QUESTIONS = [
  {
    id: 1,
    title: "Абонентская плата",
    text:
      "<p>Абонентская плата — периодическая оплата за предоставление услуг в личном кабинете. <br><br> Пользователи, имеющие тарифы Клиент Фри, Клиент Смарт, Клиент Стандарт, могут работать без абонентской платы как в кабинете клиента, так и в кабинете дистрибьютора. С них она не взимается. Пользователям с тарифами Бизнес, Премиум, Франшиза и Франшиза Голд предоставляется доступ к Программе за абонентскую плату.</p>",
  },
  {
    id: 2,
    title: "Размер абонентской оплаты",
    text:
      "<p>Плата взимается с каждого бинарного аккаунта. Размер платы за один бинарный аккаунт составляет 400 RC в месяц или 3000 RC в год. <br><br> Для тарифа Франшиза в сумму абонентской платы входит оплата ежемесячной неисключительной лицензии, что регламентировано условиями Лицензионного соглашения, заключаемого на тарифе Франшиза.</p>",
  },
  {
    id: 3,
    title: "Принцип оплаты",
    text:
      "<p>Дистрибьютор выбирает бинарные аккаунты, за которые он будет оплачивать абонентскую плату. <br><br> Если не выбран ни один из бинарных аккаунтов на момент списания оплаты, то абонентская плата не спишется, и будет считаться, что она не оплачена. <br><br> Абонентская плата списывается автоматически за выбранные бинарные аккаунты каждые 30 дней. Количество оставшихся дней до списания абонентской платы можно увидеть рядом с бинарным аккаунтом в меню «Абонентская плата». <br><br> Если абонентская плата не была оплачена вовремя, то начисления на бинарный аккаунт будут идти так же, как для тарифа Клиент Стандарт. <br><br> В случае добавления бинарного аккаунта (который ранее был не выбран), происходит списание абонентской платы, после чего начисления возобновляются.</p>",
  },
  {
    id: 4,
    title: "Периодичность оплаты",
    text:
      "<p>Возможные периоды оплаты — месяц или год. Период оплаты определяется для всех бинарных аккаунтов одновременно. Первые 30 дней после покупки тарифа абонентская плата не оплачивается отдельно, так как она входит в сумму покупки тарифа. <br><br> Если на момент списания абонентской платы у пользователя недостаточно средств для оплаты, но баланс не является отрицательным, то происходит списание за выбранные бинарные аккаунты. От этого баланс уходит в минус, но начисления продолжаются. <br><br> Если на следующий период оплаты отрицательный баланс сохраняется, то пользователю предоставляется возможность вручную (не автоматически) оплатить абонентскую плату. Если у пользователя выбран год, а оплаты хватает на месяц, то списывается сумма для оплаты за месяц. <br><br> Информацию об оплате абонентской платы можно увидеть в информационном блоке «Абонентская плата» и в списке операций.</p>",
  },
];
