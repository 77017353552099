<script>
import { mapGetters } from "vuex";
import formatTariff from "@/package/helpers/format-tariff.helpers";

export default {
  props: {
    subscriptionHistoryItem: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      getUserBinaryAccountById: "auth/getUserBinaryAccountById",
    }),

    formattedAmount() {
      return `- ${this.subscriptionHistoryItem.amount.toLocaleString()} RC`;
    },

    formattedDate() {
      return new Date(this.subscriptionHistoryItem.created_at).toLocaleString(
        [],
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
    },

    formattedIconClass() {
      return `subscription-history-item__icon_${
        this.getUserBinaryAccountById(this.subscriptionHistoryItem.bin_acc_id)
          .position
      }`;
    },

    formattedTariffName() {
      return formatTariff(
        this.getUserBinaryAccountById(this.subscriptionHistoryItem.bin_acc_id)
          .tariff.code
      );
    },
  },
};
</script>

<template>
  <div class="subscription-history-item">
    <span
      class="subscription-history-item__icon"
      :class="formattedIconClass"
    ></span>

    <div class="subscription-history-item__info info">
      <div class="info__account">
        <h6
          v-if="
            getUserBinaryAccountById(subscriptionHistoryItem.bin_acc_id).tariff
          "
        >
          {{ formattedTariffName }}
        </h6>
        <p>{{ subscriptionHistoryItem.bin_acc_id }}</p>
      </div>
      <div class="info__amount">{{ formattedAmount }}</div>
    </div>

    <p class="subscription-history-item__date">{{ formattedDate }}</p>
  </div>
</template>

<style lang="scss" scoped>
.subscription-history-item {
  display: flex;
  align-items: center;

  &:first-child {
    margin-top: $space-l;
  }

  &:not(:last-child) {
    margin-bottom: $space-xl;
  }

  &__icon {
    &_master {
      mask: url("../../assets/icons/binary_account_position_master.svg") 0 /
        cover no-repeat;
    }

    &_left {
      mask: url("../../assets/icons/binary_account_position_left.svg") 0 0 /
        cover no-repeat;
    }

    &_right {
      mask: url("../../assets/icons/binary_account_position_right.svg") 0 0 /
        cover no-repeat;
    }

    min-width: 32px;
    height: 32px;
    display: inline-block;
    background: $dark-third;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: $space-m;
  }

  .info {
    &__account {
      padding-right: $space-m;

      h6 {
        @include body-1;
        color: $dark-primary;
      }

      p {
        @include text-2;
        color: $dark-sixth;
      }
    }

    &__amount {
      @include body-1;
      color: $dark-primary;
    }
  }

  &__date {
    @include text-2;
    color: $dark-sixth;
    min-width: 120px;
    margin-left: $space-l;
    text-align: right;
  }
}
</style>
