<template>
  <div
    class="main-collapse"
    :class="{ 'main-collapse_opened': open || select }"
  >
    <div
      class="main-collapse__title"
      :class="{ 'main-collapse__title_bordered': open || select }"
      @click="selectCollapse"
    >
      <p>{{ title }}</p>

      <img src="../../assets/icons/arrow_down.svg" alt="" />
    </div>

    <slot v-if="open || select"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },

    autoClose: {
      type: Boolean,
    },

    select: {
      type: Boolean,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  methods: {
    selectCollapse() {
      if (!this.autoClose) {
        this.open = !this.open;
      }

      this.$emit("select");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-collapse {
  max-width: 782px;
  width: 100%;
  padding: $space-m $space-l 0;
  background-color: $light-primary;
  border-radius: $space-s;

  &_opened {
    padding-bottom: $space-l !important;

    .main-collapse__title {
      img {
        transform: rotate(0);
      }
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $space-m;
    cursor: pointer;

    p {
      @include body-1;
      color: $dark-primary;
    }

    img {
      transform: rotate(-180deg);
      transition: transform 0.25s ease;
    }

    &_bordered {
      border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    }
  }

  @media (max-width: 576px) {
    padding: $space-m $space-m 0;
  }
}
</style>
