<template>
  <div
    class="subscription-status"
    :class="{
      'subscription-status_error': currentStatus === 'Ожидает оплаты',
    }"
  >
    <div class="subscription-status__wrapper">
      <p class="subscription-status__duration">
        {{ formattedDistance }}
      </p>
      <p
        class="subscription-status__status"
        :class="{
          'subscription-status__status_error':
            currentStatus === 'Ожидает оплаты',
        }"
      >
        {{ currentStatus }}
      </p>
    </div>

    <div
      v-if="formattedDistance !== '0 дней'"
      class="subscription-status__expired-at"
    >
      до {{ formattedDate }} МСК
    </div>
  </div>
</template>

<script>
import { format, formatDistanceToNowStrict, isBefore } from "date-fns";
import ruLocale from "date-fns/locale/ru";

export default {
  props: {
    date: {
      type: String,
      required: true,
    },
  },

  computed: {
    formattedDate() {
      return format(new Date(this.date), "dd.MM.yyyy' 'HH:mm:ss");
    },

    formattedDistance() {
      if (isBefore(new Date(), new Date(this.date))) {
        return formatDistanceToNowStrict(new Date(this.date), {
          locale: ruLocale,
        });
      } else {
        return "0 дней";
      }
    },

    currentStatus() {
      return this.formattedDistance !== "0 дней" ? "Оплачен" : "Ожидает оплаты";
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-status {
  background-color: $light-second;
  border-radius: $space-s;
  padding: $space-m;
  height: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  &_error {
    border: 1px solid $red;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: $space-xs;
    width: 100%;
  }

  &__duration {
    @include body-1;
    color: $dark-primary;
  }

  &__status {
    @include caption-1;
    color: $green;

    &_error {
      color: $red;
    }
  }

  &__expired-at {
    @include caption-1;
    color: $dark-sixth;
  }
}
</style>
