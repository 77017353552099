<script>
import { mapGetters, mapActions } from "vuex";
import { debounce } from "throttle-debounce";
import formatTariff from "@/package/helpers/format-tariff.helpers";
import SubscriptionStatus from "@/components/subscription/SubscriptionStatus";
import MainButton from "@/components/helpers/MainButton";
import MainCheckbox from "@/components/helpers/MainCheckbox";
import MainNotice from "@/components/helpers/MainNotice.vue";

export default {
  components: {
    MainButton,
    SubscriptionStatus,
    MainCheckbox,
    MainNotice,
  },

  props: {
    subscription: {
      type: Object,
      required: true,
    },

    subscriptionType: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      checkbox: false,
      paying: false,

      isShowNotice: false,
      noticeType: "",
      noticeTitle: "",
    };
  },

  watch: {
    checkbox(newValue) {
      if (newValue !== this.subscription.is_selected) {
        this.switchSubscriptionActivation({
          binaryAccountId: this.subscription.bin_acc_id,
        }).then(() => {
          this.$emit("updateSubscriptions");
        });
      }
    },
  },

  computed: {
    ...mapGetters({
      getBinaryAccountById: "auth/getUserBinaryAccountById",
    }),

    formattedIconClass() {
      return `title__position-icon_${
        this.getBinaryAccountById(this.subscription.bin_acc_id).position
      }`;
    },

    formattedPrice() {
      return `${this.subscriptionType?.price ?? 0} RC`;
    },

    formattedTariffName() {
      return formatTariff(
        this.getBinaryAccountById(this.subscription.bin_acc_id).tariff.code
      );
    },
  },

  methods: {
    ...mapActions({
      switchSubscriptionActivation: "subscription/switchSubscriptionActivation",
      paySubscription: "subscription/paySubscription",
      checkSubscription: "subscription/checkSubscription",
    }),

    debouncePaySubscription: debounce(300, function () {
      this.paying = true;
      this.isShowNotice = false;

      this.paySubscription({
        binaryAccountId: this.subscription.bin_acc_id,
        mode: this.subscriptionType.mode,
      })
        .then(() => {
          this.paying = false;
          this.noticeType = "success";
          this.noticeTitle = "Абонентская плата внесена";
          this.isShowNotice = true;

          this.$emit("updateSubscriptions");
          this.$emit("updateBalance");
        })
        .catch(() => {
          this.noticeType = "error";
          this.noticeTitle = "Абонентская плата не внесена";
          this.isShowNotice = true;
        });
    }),
  },

  mounted() {
    this.checkbox = this.subscription.is_selected;
  },
};
</script>

<template>
  <div class="subscription-item">
    <div class="subscription-item__title title">
      <div class="title__tariff">
        <span class="title__position-icon" :class="formattedIconClass" />
        <h4 v-if="getBinaryAccountById(subscription.bin_acc_id).tariff">
          {{ formattedTariffName }}
        </h4>
      </div>
      <p class="title__account-id">{{ subscription.bin_acc_id }}</p>
    </div>

    <subscription-status
      class="subscription-item__status"
      :date="subscription.active_until"
    />

    <div class="subscription-item__payment payment">
      <div class="payment__price">
        <h3>{{ formattedPrice }}</h3>
        <p v-if="subscriptionType.mode === 'annual_payment'">35% скидка</p>
      </div>

      <main-button
        class="payment__button"
        title="Оплатить"
        color="dark"
        :disabled="checkbox || paying"
        @click="debouncePaySubscription"
      />
    </div>

    <div class="subscription-item__automatic-payment">
      <main-checkbox
        :id="subscription.bin_acc_id"
        v-model="checkbox"
        :model-value="subscription.is_selected"
        title="Оплачивать автоматически"
        title-position="left"
      />
    </div>

    <div class="subscription-item__bottom-block"></div>

    <main-notice
      v-if="isShowNotice"
      :title="noticeTitle"
      :notice-type="noticeType"
      @close="isShowNotice = false"
    />
  </div>
</template>

<style lang="scss">
.subscription-item {
  background-color: $light-primary;
  border-radius: $space-m;
  padding: $space-l;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  &__title {
    margin-bottom: $space-m;
  }

  .title {
    &__tariff {
      display: flex;
      align-items: center;
      margin-bottom: $space-xs;

      h4 {
        @include title-3;
        color: $dark-primary;
      }
    }

    &__account-id {
      @include caption-1;
      color: $light-sixth;
    }

    &__position-icon {
      &_master {
        mask: url("../../assets/icons/binary_account_position_master.svg") 0 0 /
          cover no-repeat;
      }

      &_left {
        mask: url("../../assets/icons/binary_account_position_left.svg") 0 0 /
          cover no-repeat;
      }

      &_right {
        mask: url("../../assets/icons/binary_account_position_right.svg") 0 0 /
          cover no-repeat;
      }

      margin-right: $space-xs;
      display: inline-block;
      width: 24px;
      height: 24px;
      background: $dark-primary;
    }
  }

  &__status {
    margin-bottom: $space-xxl;
  }

  &__switch {
    margin-bottom: $space-l;
  }

  &__payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space-xxl;
  }

  .payment {
    &__price {
      h3 {
        @include title-3;
        color: $dark-primary;
        margin-bottom: $space-xs;
        white-space: nowrap;
        margin-right: 12px;
      }

      p {
        @include caption-1;
        color: $dark-sixth;
      }
    }

    &__button {
      max-width: 158px;
    }
  }

  &__automatic-payment {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    z-index: 10;
  }

  &__bottom-block {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 68px;
    width: 100%;
    background-color: $gold-main;
  }
}
</style>
