<template>
  <div class="subscription-error">
    <div class="subscription-error__description">
      {{ description }}
    </div>

    <div class="subscription-error__button">
      <tooltip-up-balance
        v-if="showUpBalanceTooltip"
        title="Пополнение баланса"
        description="Пополните баланс через банковскую карту"
        tooltip-position="bottom"
        @close="showUpBalanceTooltip = false"
      />

      <main-button
        title="Пополнить баланс"
        color="gold"
        @click="showUpBalanceTooltip = true"
      />
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/helpers/MainButton";
import TooltipUpBalance from "@/components/tooltips/TooltipUpBalance";

export default {
  components: {
    TooltipUpBalance,
    MainButton,
  },

  props: {
    description: {
      type: String,
    },
  },

  data() {
    return {
      showUpBalanceTooltip: false,
    };
  },
};
</script>

<style scoped lang="scss">
.subscription-error {
  max-width: 660px;
  border-radius: 8px;
  background-color: $dark-primary;
  padding: $space-l;
  margin: 0 0 $space-xl;

  @media (max-width: 1024px) {
    width: 100%;
  }

  &__description {
    margin: 0 0 $space-m;
    color: $red;
    @include text-2;
  }

  &__button {
    max-width: 300px;
    width: 100%;
    position: relative;

    @media (max-width: 360px) {
      width: 100%;
    }
  }
}
</style>
